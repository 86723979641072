import React, { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow,
    Button,
    TextField,
} from '@mui/material';
import { DatePicker } from '@mui/lab';
import ruLocale from "date-fns/locale/ru";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { downloadReportAction, findReportAction } from '../../redux/actions/reportsActions'
import { Context } from '../../AppContext';
import { AlertComponent, Loader, SearchInput } from '../../components';
import { BASE_URL } from '../../API/baseUrl';
import { downloadExel } from '../../helpers/exportToExel'

const Vehicles = () => {
    const { t } = useTranslation()
    const [searchValue, setsearchValue] = useState('');
    const history = useHistory()
    const dispatch = useDispatch()
    const { isLoading } = useSelector(state => state.reports)
    const report_name = history.location.pathname.slice(1)

    const { token, expired_at, begin,
        end, beginDate, setBeginDate,
        endDate, setEndDate, VehiclesHeader } = useContext(Context)

    useEffect(() => {
        dispatch(downloadReportAction(
            report_name,
            token,
            expired_at
        ));
        return dispatch(downloadReportAction(report_name, token, expired_at))
    }, [report_name,
        token,
        expired_at, dispatch])

    const { report, error } = useSelector(({ reports }) => reports)
    const resulTable = report?.ArrivalDocuments?.ArrivalDocument

    const filterData = resulTable && Object.values(resulTable).filter(el => {
        return el?.DocumentNumber?.toLowerCase().includes(searchValue.toLowerCase()) ||
            el?.GovernmentNumber?.toLowerCase().includes(searchValue.toLowerCase())
    })

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(findReportAction(
            report_name, begin,
            end, token, expired_at))
    }

    return (
        <>
            <div className="report">
                <div className="report__form-header">
                    <SearchInput
                        toolText={t("tooltip.vehicle")}
                        value={searchValue}
                        setValue={setsearchValue} />

                    <FormControl fullWidth>
                        <FormGroup>
                            <form onSubmit={onSubmit}>
                                <div className="report__form-options">
                                    <div className="report__form-date">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={ruLocale}>
                                            <DatePicker
                                                mask="mm.dd.yy"
                                                label={`${t("calendar.begin_date")}`}
                                                value={beginDate}
                                                maxDate={endDate}
                                                onChange={(newValue) => setBeginDate(newValue)}
                                                renderInput={(params) => <TextField
                                                    className="datepicker"
                                                    {...params}
                                                />}
                                            />
                                        </LocalizationProvider>

                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={ruLocale}>
                                            <DatePicker
                                                label={`${t("calendar.end_date")}`}
                                                value={endDate}
                                                maxDate={endDate}
                                                onChange={(newValue) => setEndDate(newValue)}
                                                renderInput={(params) => <TextField
                                                    className="datepicker"
                                                    {...params}
                                                />}
                                            />
                                        </LocalizationProvider>
                                        <Button type="submit">
                                            <p className="btn"> {t("button.show")}</p>
                                        </Button>
                                    </div>

                                </div>
                            </form>
                        </FormGroup>
                    </FormControl>
                    <button
                        className="btn-sm"
                        onClick={() => downloadExel(report_name, "#table")}> <p> {t("button.download_excel")} </p>
                    </button>
                </div>

                {error ?
                    <AlertComponent t={t} isOpen={true} error={error} /> : null}                

                {isLoading ? <Loader /> :
                    <div className="report__form-data">
                        <TableContainer className="table__height">
                            <Table stickyHeader id='table'>
                                <TableHead>
                                    <TableRow hover>
                                        {VehiclesHeader.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    textAlign: "center",
                                                    background: "#f4f4f4",
                                                    borderRight: "1px solid #ccc",
                                                }}
                                                className="table-header"
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {resulTable ? (
                                    Array.isArray(resulTable) ? (
                                        <TableBody>
                                            {filterData?.map((el, index) => (
                                                <TableRow key={index} hover>
                                                    <TableCell className="table-cell">
                                                        {el.DocumentNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.ArrivalDate}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.DepartureDate}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.GovernmentNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.TransactionType}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Country}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.VehicleType}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.NumberPallets}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Tonnage}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.State}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Driver}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Phone}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Files?.File?.length > 1 ? (
                                                            el.Files?.File.map(file => (
                                                                <b key={file?.name}> <Link
                                                                    className="dock"
                                                                    to={{
                                                                        pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.DocumentNumber}&filename=${file?.name}`
                                                                    }} target="_blank" download={file?.name}>
                                                                    {file?.name} <br />
                                                                </Link> </b>
                                                            )))
                                                            :
                                                            (<b> <p>
                                                                <Link
                                                                    className="dock"
                                                                    to={{
                                                                        pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.DocumentNumber}&filename=${el.Files?.File?.name}`
                                                                    }} target="_blank" download={el.Files?.File?.name}>
                                                                    {el.Files?.File?.name}
                                                                </Link>
                                                            </p> </b>
                                                            )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    ) :
                                        (
                                            <TableBody>
                                                <TableRow hover>
                                                    <TableCell className="table-cell">
                                                        {resulTable.DocumentNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.ArrivalDate}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.DepartureDate}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.GovernmentNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.TransactionType}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.Country}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.VehicleType}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.NumberPallets}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.Tonnage}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.State}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.Driver}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.Phone}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.Files?.File?.length > 1 ? (
                                                            resulTable.Files?.File.map(file => (
                                                                <b key={file?.name}> <Link
                                                                    className="dock"
                                                                    to={{
                                                                        pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.DocumentNumber}&filename=${file?.name}`
                                                                    }} target="_blank" download={file?.name}>
                                                                    {file?.name} <br />
                                                                </Link> </b>
                                                            )))
                                                            :
                                                            (<b> <p>
                                                                <Link
                                                                    className="dock"
                                                                    to={{
                                                                        pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.DocumentNumber}&filename=${resulTable.Files?.File?.name}`
                                                                    }} target="_blank" download={resulTable.Files?.File?.name}>
                                                                    {resulTable.Files?.File?.name}
                                                                </Link>
                                                            </p> </b>
                                                            )}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )
                                ) :
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={16} className="row">
                                                <p>{t("data.no_data")}</p>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer>
                    </div>
                }
            </div>
        </>
    )
}

export default Vehicles
