import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  LOADING,
} from "../actions/authActions";

const user = JSON.parse(localStorage.getItem("tokenData"));

export const initialState = user
  ? {
      isLogged: true,
      user,
      errorMessage: "",
      isLoading: false,
    }
  : { isLogged: false, user: null, register: false };

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_LOADING:
      return {
        ...state,
        loadingServer: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        register: true,
        loadingServer: false,
        error: null,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        register: false,
        error: action.payload,
        loadingServer: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLogged: true,
        user: action.payload,
        errorMessage: null,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isLogged: false,
        isLoading: false,
        user: null,
        errorMessage: action.payload,
      };
      
    case LOGOUT:
      return {
        ...state,
        user: null,
        isLogged: false,
      };
    case LOADING:
      return {
        ...state,
        isLoading: action.payload
      };

    default:
      return state;
  }
};

export default authReducer;

