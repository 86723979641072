import React, { useContext, useState, useEffect } from 'react'
import {
    FormControl,
    FormGroup,
    Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow,
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useDispatch, useSelector } from 'react-redux'
import { downloadReportAction, findReportAction } from '../../redux/actions/reportsActions'
import { Context } from '../../AppContext';
import { SearchInput, Loader } from '../../components/index';
import { useHistory } from 'react-router';
import ruLocale from "date-fns/locale/ru";

import './style.scss'
import { BASE_URL } from '../../API/baseUrl';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DatePicker } from '@mui/lab';
import { downloadExel } from '../../helpers/exportToExel'
import AlertComponent from '../../components/AlertComponent/index';

const InvoicesTable = () => {
    const [searchValue, setsearchValue] = useState('');
    const [archivalChecked, setArchivalChecked] = useState(false);
    const dispatch = useDispatch()
    const history = useHistory()
    let report_name = history.location.pathname.slice(1)
    const { token, expired_at, begin,
        end, beginDate, setBeginDate,
        endDate, setEndDate, InvoicesHeader } = useContext(Context)
    const { report, error } = useSelector(({ reports }) => reports)
    const { isLoading } = useSelector(state => state.reports)
    const resulTable = report?.SalesDocuments?.SalesDocument
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(downloadReportAction(
            report_name,
            token,
            expired_at,
            archivalChecked))
    }, [report_name, token,
        expired_at, archivalChecked, dispatch])

    const filterData = resulTable && Object.values(resulTable).filter(el => {
        return el?.DocumentNumber?.toLowerCase().includes(searchValue.toLowerCase()) ||
            el?.Vehicle?.includes(searchValue)
    })

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(findReportAction(
            report_name, begin,
            end, token, expired_at))
    }

    return (
        <>
            <div className="report">
                <div className="report__form-header">
                    <SearchInput
                        toolText={t("tooltip.invoices")}
                        value={searchValue}
                        setValue={setsearchValue} />

                    <FormControl fullWidth>
                        <FormGroup>
                            <form onSubmit={onSubmit}>
                                <div className="report__form-options">
                                    <div className="report__form-date">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={ruLocale}>
                                            <DatePicker
                                                mask="mm.dd.yy"
                                                label={`${t("calendar.begin_date")}`}
                                                value={beginDate}
                                                maxDate={endDate}
                                                onChange={(newValue) => setBeginDate(newValue)}
                                                renderInput={(params) => <TextField
                                                    className="datepicker"
                                                    {...params}
                                                />}
                                            />
                                        </LocalizationProvider>

                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={ruLocale}>
                                            <DatePicker
                                                label={`${t("calendar.end_date")}`}
                                                value={endDate}
                                                maxDate={endDate}
                                                onChange={(newValue) => setEndDate(newValue)}
                                                renderInput={(params) => <TextField
                                                    className="datepicker"
                                                    {...params}
                                                />}
                                            />
                                        </LocalizationProvider>

                                        <div className="report__form-check">
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={archivalChecked} onChange={(e) => setArchivalChecked(e.target.checked)}
                                                />}
                                                label={t("form.hide_paid")}
                                            />
                                        </div>

                                        <Button type="submit">
                                            <p className="btn">
                                                {t("button.show")}
                                            </p>
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </FormGroup>
                    </FormControl>
                    <button
                        className="btn-sm"
                        onClick={() => downloadExel(report_name, "#table")}> <p> {t("button.download_excel")} </p>
                    </button>
                </div>
                {error ?
                    <AlertComponent t={t} isOpen={true} error={error} /> : null}

                {isLoading ? <Loader /> :
                    <div className="report__form-data">
                        <TableContainer className="table__height">
                            <Table stickyHeader id='table'>
                                <TableHead>
                                    <TableRow hover>
                                        {InvoicesHeader.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    textAlign: "center",
                                                    background: "#f4f4f4", borderRight: "1px solid #ccc"
                                                }}
                                                className="table-header">
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                {resulTable ? (
                                    Array.isArray(resulTable) ? (
                                        <TableBody>
                                            {filterData?.map(el => (
                                                <TableRow key={el.DocumentNumber} hover>
                                                    <TableCell className={
                                                        el.Paid === "true"
                                                            ? "table-cell status" : "table-cell"}>
                                                        {el.DocumentNumber}
                                                    </TableCell>
                                                    <TableCell className={
                                                        el.Paid === "true"
                                                            ? "table-cell status" : "table-cell"}>
                                                        {el.UnloadDate}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            el.Paid === "true"
                                                                ? "table-cell status" : "table-cell"}>
                                                        {el.Vehicle}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            el.Paid === "true"
                                                                ? "table-cell status" : "table-cell"}>
                                                        {el.Counterparty}
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            el.Paid === "true"
                                                                /* || el.DateOfPaymentFact !== ""  */
                                                                ? "table-cell status" : "table-cell"}>
                                                        {el.PriceEuro} € <br />
                                                        {el.PriceRub} ₽
                                                    </TableCell>
                                                    <TableCell className={
                                                        el.Paid === "true"
                                                            ? "table-cell status" : "table-cell"}>
                                                        {el.DateOfPaymentPlan}
                                                    </TableCell>
                                                    <TableCell className={
                                                        el.Paid === "true"
                                                            ? "table-cell status" : "table-cell"}>
                                                        {el.BaseDocumentType}&nbsp;
                                                        {el.BaseDocumentNumber}&nbsp;
                                                        от&nbsp;{el.UnloadDate}
                                                    </TableCell>
                                                    <TableCell className={
                                                        el.Paid === "true"
                                                            ? "table-cell status" : "table-cell"}>
                                                        {el.ContactPerson}
                                                    </TableCell>
                                                    <TableCell className={
                                                        el.Paid === "true"
                                                            ? "table-cell status" : "table-cell"}>
                                                        {el.Files?.File?.length > 1 ? (
                                                            el.Files?.File.map(file => (
                                                                <b key={file.name}>
                                                                    <Link
                                                                        className="dock"
                                                                        to={{
                                                                            pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.DocumentNumber}&filename=${file.name}`
                                                                        }} target="_blank" download={file.name}>
                                                                        {file.name}
                                                                        <br />
                                                                    </Link> </b>
                                                            )))
                                                            :
                                                            (<b key={el?.Files?.File.name}> <p>
                                                                <Link
                                                                    className="dock"
                                                                    to={{
                                                                        pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.DocumentNumber}&filename=${el?.Files?.File?.name}`
                                                                    }} target="_blank" download={el?.Files?.File.name}>
                                                                    {el?.Files?.File.name}
                                                                </Link>
                                                            </p> </b>
                                                            )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow hover>
                                                <TableCell className={
                                                    resulTable.Paid === "true"
                                                        ? "table-cell status" : "table-cell"}>
                                                    {resulTable.DocumentNumber}
                                                </TableCell>
                                                <TableCell className={
                                                    resulTable.Paid === "true"
                                                        ? "table-cell status" : "table-cell"}>
                                                    {resulTable.UnloadDate}
                                                </TableCell>
                                                <TableCell className={
                                                    resulTable.Paid === "true"
                                                        ? "table-cell status" : "table-cell"}>
                                                    {resulTable.Vehicle}
                                                </TableCell>
                                                <TableCell className={
                                                    resulTable.Paid === "true"
                                                        ? "table-cell status" : "table-cell"}>
                                                    {resulTable.Counterparty}
                                                </TableCell>
                                                <TableCell className={
                                                    resulTable.Paid === "true"
                                                        /*     || resulTable.DateOfPaymentFact !== ""  */
                                                        ? "table-cell status" : "table-cell"}>
                                                    {resulTable.PriceEuro} € <br />
                                                    {resulTable.PriceRub} ₽
                                                </TableCell>
                                                <TableCell className={
                                                    resulTable.Paid === "true"
                                                        ? "table-cell status" : "table-cell"}>
                                                    {resulTable.DateOfPaymentPlan}
                                                </TableCell>
                                                <TableCell className={
                                                    resulTable.Paid === "true"
                                                        ? "table-cell status" : "table-cell"}>
                                                    {resulTable.BaseDocumentType}&nbsp;
                                                    {resulTable.BaseDocumentNumber}&nbsp;
                                                    от&nbsp;{resulTable.UnloadDate}
                                                </TableCell>
                                                <TableCell className={
                                                    resulTable.Paid === "true"
                                                        ? "table-cell status" : "table-cell"}>
                                                    {resulTable.ContactPerson}
                                                </TableCell>
                                                <TableCell className={
                                                    resulTable.Paid === "true"
                                                        ? "table-cell status" : "table-cell"}>
                                                    {resulTable?.Files?.File?.length > 1 ? (
                                                        resulTable?.Files?.File.map(file => (
                                                            <b key={file.name}>
                                                                <Link
                                                                    className="dock"
                                                                    to={{
                                                                        pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.DocumentNumber}&filename=${file.name}`
                                                                    }} target="_blank" download={file.name}>
                                                                    {file.name}
                                                                    <br />
                                                                </Link> </b>
                                                        )))
                                                        :
                                                        (<b key={resulTable?.Files?.File.name}> <p>
                                                            <Link
                                                                className="dock"
                                                                to={{
                                                                    pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.DocumentNumber}&filename=${resulTable?.Files?.File.name}`
                                                                }} target="_blank" download={resulTable?.Files?.File.name}>
                                                                {resulTable?.Files?.File.name}
                                                            </Link>
                                                        </p> </b>
                                                        )}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                ) : <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={16} className="row">
                                            <p>{t("data.no_data")}</p>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>}
                            </Table>
                        </TableContainer>
                    </div>
                }
            </div>
        </>
    )
}

export default InvoicesTable
