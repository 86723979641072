import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router';
import {
    Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow
} from '@mui/material';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { downloadExel } from '../../helpers/exportToExel'

import { Context } from '../../AppContext';
import { downloadReportAction } from '../../redux/actions/reportsActions';
import { BASE_URL } from '../../API/baseUrl';
import { Loader } from '../../components';
import AlertComponent from '../../components/AlertComponent/index';

const VehiclesZtk = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const report_name = history.location.pathname.slice(1)
    const { report, error } = useSelector(({ reports }) => reports)
    const resulTable = report?.ArrivalDocuments?.ArrivalDocument
    const { t } = useTranslation()
    const { token, expired_at, VehiclesZtkHeader } = useContext(Context)
    const { isLoading } = useSelector(state => state.reports)

    useEffect(() => {
        dispatch(downloadReportAction(
            report_name,
            token,
            expired_at))
    }, [report_name,
        token,
        expired_at, dispatch])

    return (
        <div className="report">
            <div className='report__form-header_rigth'>
                <button
                    className="btn-sm"
                    onClick={() => downloadExel(report_name, "#table")}> <p> {t("button.download_excel")} </p>
                </button>
            </div>

            {error ?
                <AlertComponent t={t} isOpen={true} error={error} /> : null}

            {isLoading ? <Loader /> :
                <div className="report__form-data">
                    <TableContainer className="table__height">
                        <Table stickyHeader id='table'>
                            <TableHead
                            >
                                <TableRow hover>
                                    {VehiclesZtkHeader.map((column, index) => (
                                        <TableCell
                                            key={index}
                                            style={{
                                                minWidth: column.minWidth,
                                                textAlign: "center",
                                                background: "#f4f4f4",
                                                borderRight: "1px solid #ccc",
                                            }}
                                            className="table-header"
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {resulTable ? (
                                Array.isArray(resulTable) ? (
                                    <TableBody>
                                        {resulTable.map((el, index) => (
                                            <TableRow key={index}>
                                                <TableCell className="table-cell">
                                                    {el.DocumentNumber}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el.ArrivalDate}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el.Counterparty}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el.GovernmentNumber}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el.State}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el.TransitDeclaration}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el.Comment}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el?.Files?.File?.length > 1 ? (
                                                        el?.Files?.File.map(file => (
                                                            <b key={file.name}> <Link
                                                                className="dock"
                                                                to={{
                                                                    pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.DocumentNumber}&filename=${file.name}`
                                                                }} target="_blank" download={file.name}>
                                                                {file.name} <br />
                                                            </Link> </b>
                                                        )))
                                                        :
                                                        (<b> <p>
                                                            <Link
                                                                className="dock"
                                                                to={{
                                                                    pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.DocumentNumber}&filename=${el?.Files?.File?.name}`
                                                                }} target="_blank" download={el?.Files?.File?.name}>
                                                                {el?.Files?.File?.name}
                                                            </Link>
                                                        </p> </b>
                                                        )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                ) :
                                    (
                                        <TableBody>
                                            <TableRow hover>
                                                <TableCell className="table-cell">
                                                    {resulTable.DocumentNumber}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable.ArrivalDate}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable.Counterparty}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable.GovernmentNumber}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable.State}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable.TransitDeclaration}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable.Comment}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable?.Files?.File.length > 1 ? (
                                                        resulTable?.Files?.File.map(file => (
                                                            <b key={file.name}> <Link
                                                                className="dock"
                                                                to={{
                                                                    pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.DocumentNumber}&filename=${file.name}`
                                                                }} target="_blank" download={file.name}>
                                                                {file.name} <br />
                                                            </Link> </b>
                                                        )))
                                                        :
                                                        (<b> <p>
                                                            <Link
                                                                className="dock"
                                                                to={{
                                                                    pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.DocumentNumber}&filename=${resulTable?.Files?.File?.name}`
                                                                }} target="_blank" download={resulTable?.Files?.File?.name}>
                                                                {resulTable?.Files?.File?.name}
                                                            </Link>
                                                        </p> </b>
                                                        )}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>)
                            )
                                :
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={16} className="row">
                                            <p>{t("data.no_data")}</p>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                </div>
            }

        </div>
    )
}
export default VehiclesZtk

