import React, { useState } from 'react'
import { Alert, Snackbar } from '@mui/material'

const AlertComponent = ({ error, isOpen, t }) => {
    const [open, setOpen] = useState(isOpen)

    return (
        <>
            <Snackbar
                onClose={() => setOpen(false) }
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}>
                <Alert severity={error ? "error" : "success"}
                    onClose={() => setOpen(false) }
                >
                    {error}
                 </Alert>
            </Snackbar>
        </>
    )
}

export default AlertComponent
