import { BASE_URL } from "../../API/baseUrl";
import { findReport } from "../../API/reportApi";
import { logoutAction } from "./authActions";
import axios from "axios";

export const LOADING_REPORT = "LOADING_REPORT";
export const SUCCESS_GET_REPORT = "SUCCESS_GET_REPORT";
export const ERROR_GET_REPORT = "ERROR_GET_REPORT";

export const SESSION_FAILED = "SESSION_FAILED";

export const loadingReports = (payload) => ({
  type: LOADING_REPORT,
  payload,
});
export const successGetReport = (payload) => ({
  type: SUCCESS_GET_REPORT,
  payload,
});
export const errorGetReport = (payload) => ({
  type: ERROR_GET_REPORT,
  payload,
});

export const sessionFailed = (payload) => ({
  type: SESSION_FAILED,
  payload,
});

export const downloadReportAction =
  (report_name, token, expired_at, archivalChecked) => async (dispatch) => {
    dispatch(loadingReports(true));
    try {
      const data = await axios.get(`${BASE_URL}/report`, {
        params: {
          report_name: report_name,
          token: token,
          expired_at: expired_at,
          archival: archivalChecked,
        },
      });
      if (data.status === 200) {
        dispatch(successGetReport(data.data));
        dispatch(loadingReports(false));
      }
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(sessionFailed(true));
        dispatch(logoutAction());
        localStorage.removeItem("tokenData");
      }
      else if (err.response.status !== 401) {
          dispatch(errorGetReport(err.response.data.errors.detail)); 
      } 
    } finally {
      dispatch(loadingReports(false));
    }
  };

export const findReportAction =
  (report_name, begin, end, token, expired_at, archivalChecked) =>
  async (dispatch) => {
    try {
      dispatch(loadingReports(true));
      const data = await findReport(
        report_name,
        begin,
        end,
        token,
        expired_at,
        archivalChecked
      );
      dispatch(successGetReport(data));
      dispatch(loadingReports(false));
    } catch (err) {
      dispatch(errorGetReport(err));
    } finally {
      dispatch(loadingReports(false));
    }
  };
