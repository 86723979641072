import {
  SUCCESS_GET_REPORT,
  ERROR_GET_REPORT,
  LOADING_REPORT,
  SESSION_FAILED,
} from "../actions/reportsActions";

const initialStaete = {
  report: null,
  error: null,
  isLoading: false,
  sessionFailed: false
};

const reportshReducer = (state = initialStaete, action) => {
  switch (action.type) {
    case LOADING_REPORT:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SUCCESS_GET_REPORT:
      return {
        ...state,
        report: action.payload,
        isLoading: false,
        error: null,
      };
    case ERROR_GET_REPORT:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case SESSION_FAILED:
      return {
        ...state,
        sessionFailed: action.payload,
      };
    default:
      return state;
  }
};
export default reportshReducer