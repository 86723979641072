import axios from "axios";
import AuthAPI from "../../API/authApi";
import { BASE_URL } from "../../API/baseUrl";

export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOADING = "LOADING";
export const LOGOUT = "LOGOUT";

export const registerRequestAction = () => ({
  type: REGISTER_LOADING,
});

export const registerSuccessAction = () => ({
  type: REGISTER_SUCCESS,
});

export const registerErrorAction = (payload) => ({
  type: REGISTER_ERROR,
  payload,
});

export const loginSuccessAction = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});
export const loginErrorAction = (payload) => ({
  type: LOGIN_ERROR,
  payload,
});

export const loadingAction = (payload) => ({
  type: LOADING,
  payload,
});

export const logoutAction = () => ({
  type: LOGOUT,
});

export const registerAC = (firstName, lastName, email, phone, password, company) =>
  async (dispatch) => {
    try {
       await axios.post(
        `${BASE_URL}/registration/?login=${email}&password=${password}&lastname=${lastName}&name=${firstName}&phonenumber=${phone}&company=${company}`
      );
      dispatch(registerSuccessAction());
    } catch (err) {
      dispatch(registerErrorAction(err));
    } finally {
    }
  };

export const loginAC = (login, password) => async (dispatch) => {
  try {
    dispatch(loadingAction(true));
    const data = await axios.post(
      `${BASE_URL}/session/?login=${login}&password=${password}`
    );
    if (data.status === 200) {
      dispatch(loginSuccessAction(data.data));
      localStorage.setItem("tokenData", JSON.stringify(data.data));
    } else {
    }

    dispatch(loadingAction(false));
  } catch (err) {
    dispatch(loginErrorAction(err));
    dispatch(loadingAction(false));
  } finally {
    dispatch(loadingAction(false));
  }
};

export const logoutAC = () => (dispatch) => {
  AuthAPI.logout();
  dispatch(logoutAction());
};
