import { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { optionsRu } from "./helpers/dateRu";
import { useTranslation } from "react-i18next";

export const Context = createContext();

const AppContext = ({ children }) => {
  const beginDatePick = new Date().setMonth(new Date().getMonth() - 1);
  const endDatePick = new Date();

  const { user } = useSelector((state) => state.auth);
  const [beginDate, setBeginDate] = useState(beginDatePick);
  const [endDate, setEndDate] = useState(endDatePick);
  const { t } = useTranslation();

  const resBegin = beginDate?.toLocaleString("ru", optionsRu)
    .match(/^(\d{1,2}).(\d{1,2}).(\d{2})(\d{2})$/);
  const begin = resBegin?.input;

  const resEnd = endDate?.toLocaleString("ru", optionsRu)
    .match(/^(\d{1,2}).(\d{1,2}).(\d{2})(\d{2})$/);
  const end = resEnd?.input;

  const token = user?.token;
  const expired_at = user?.expired_at;

  const CargoStatusHeader = [
    {
      key: "2",
      minWidth: 70,
      label: `${t("cargo_status.number")}`,
    },
    {
      key: "Date",
      label: `${t("cargo_status.date_receipt")}`,
      minWidth: 120,
    },
    {
      key: "",
      label: `${t("cargo_status.vehicle")}`,
      minWidth: 120,
    },
    {
      key: "5",
      label: `${t("cargo_status.driver")}`,
      minWidth: 170,
    },
    {
      key: "6",
      label: `${t("cargo_status.sender")}`,
      minWidth: 170,
    },
    {
      key: "7",
      label: `${t("cargo_status.loading_address")}`,
      minWidth: 170,
    },
    {
      key: "8",
      label: `${t("cargo_status.account_number")}`,
      minWidth: 170,
    },
    {
      key: "9",
      label: `${t("cargo_status.td_number")}`,
      minWidth: 170,
    },
    {
      key: "10",
      label: `${t("cargo_status.description")}`,
      minWidth: 230,
    },
    {
      key: "11",
      label: `${t("cargo_status.contact_person")}`,
      minWidth: 170,
    },
    {
      key: "12",
      label: `${t("cargo_status.status")}`,
      minWidth: 170,
    },
    {
      key: "13",
      label: `${t("cargo_status.cargo_value")}`,
      minWidth: 170,
    },

    {
      key: "14",
      label: `${t("cargo_status.ldm")}`,
      minWidth: 50,
    },
    {
      key: "15",
      label: `${t("cargo_status.tonnage")}`,
      minWidth: 70,
    },
    {
      key: "16",
      label: `${t("cargo_status.transportation_cost")}`,
      minWidth: 120,
    },
    {
      key: "SupplierLoadingPlan",
      label: `${t("cargo_status.planned_loading_supplier")}`,
      minWidth: 170,
    },
    {
      key: "SupplierLoadingFact",
      label: `${t("cargo_status.actual_loading_supplier")}`,
      minWidth: 170,
    },
    {
      key: "SupplierUnLoadingPlan",
      label: `${t("cargo_status.planned_unloading_supplier")}`,
      minWidth: 170,
    },
    {
      key: "SupplierUnLoadingFact",
      label: `${t("cargo_status.actual_unloading_supplier")}`,
      minWidth: 170,
    },
    {
      key: "ToCrossDockLoadingPlan",
      label: `${t("cargo_status.planned_loading_after_cross")}`,
      minWidth: 170,
    },
    {
      key: "ToCrossDockLoadingFact",
      label: `${t("cargo_status.actual_loading_after_cross")}`,
      minWidth: 170,
    },
    {
      key: "ToCrossDockUnLoadingPlan",
      label: `${t("cargo_status.planned_delivery_cross")}`,
      minWidth: 170,
    },
    {
      key: "ToCrossDockUnLoadingFact",
      label: `${t("cargo_status.actual_delivery_cross")}`,
      minWidth: 170,
    },
    {
      key: "19",
      label: `${t("cargo_status.arrival_date")}`,
      minWidth: 170,
    },
    {
      key: "20",
      label: `${t("invoices.documents")}`,
      minWidth: 170,
    },
  ];

  const InvoicesHeader = [
       {
         id: "2",
         minWidth: 70,
         label: `${t("invoices.document_number")}`,
       },
       {
         id: "3",
         label: `${t("invoices.unloading_date")}`,
         minWidth: 120,
         format: (value) => value.toLocaleString("en-US"),
       },
       {
         id: "4",
         label: `${t("invoices.vehicle")}`,
         minWidth: 120,
         format: (value) => value.toLocaleString("en-US"),
       },
       {
         id: "5",
         label: `${t("invoices.counterparty")}`,
         minWidth: 170,
         format: (value) => value.toFixed(2),
       },
       {
         id: "6",
         label: `${t("invoices.amount")}`,
         minWidth: 170,
         format: (value) => value.toFixed(2),
       },
       {
         id: "7",
         label: `${t("invoices.date_payment")}`,
         minWidth: 170,
         format: (value) => value.toFixed(2),
       },
       {
         id: "8",
         label: `${t("invoices.basis")}`,
         minWidth: 170,
         format: (value) => value.toFixed(2),
       },
       {
         id: "9",
         label: `${t("invoices.contact_person")}`,
         minWidth: 170,
         format: (value) => value.toFixed(2),
       },
       {
         id: "10",
         label: `${t("invoices.documents")}`,
         minWidth: 170,
         format: (value) => value.toFixed(2),
       },
  ];
  const VehiclesZtkHeader = [
      {
        id: "1",
        label: "№",
      },
      {
        id: "2",
        minWidth: 70,
        label: `${t("vehicles_ztk.arrival_date")}`,
      },
      {
        id: "3",
        label: `${t("vehicles_ztk.recipient")}`,
        minWidth: 120,
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "4",
        label: `${t("vehicles_ztk.state_number")}`,
        minWidth: 120,
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "5",
        label: `${t("vehicles_ztk.condition")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: "6",
        label: `${t("vehicles_ztk.transit_declaration")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: "7",
        label: `${t("vehicles_ztk.comment")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: "8",
        label: `${t("vehicles_ztk.documents")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
  ];
  const VehiclesHeader = [
    {
      id: "0",
      minWidth: 120,
      label: `${t("vehicles.document_number")}`,
    },
    {
      id: "1",
      minWidth: 120,
      label: `${t("vehicles.arrival_date")}`,
    },
    {
      id: "2",
      label: `${t("vehicles.date_departure")}`,
      minWidth: 170,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "3",
      label: `${t("vehicles.state_number")}`,
      minWidth: 170,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "4",
      label: `${t("vehicles.deal_type")}`,
      minWidth: 170,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "5",
      label: `${t("vehicles.country")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "6",
      label: `${t("vehicles.vehicle_type")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "7",
      label: `${t("vehicles.number_pallets")}`,
      minWidth: 120,
      format: (value) => value.toFixed(2),
    },
    {
      id: "8",
      label: `${t("vehicles.cargo_tonnage")}`,
      minWidth: 120,
      format: (value) => value.toFixed(2),
    },
    {
      id: "9",
      label: `${t("vehicles.state")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "10",
      label: `${t("vehicles.driver")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "11",
      label: `${t("vehicles.phone")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "12",
      label: `${t("vehicles.documents")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
  ];
  const StockBalancesHeader = [
    {
      id: "1",
      label: `${t("stock_balances.cargo_id")}`,
    },
    {
      id: "2",
      minWidth: 70,
      label: `${t("stock_balances.document")}`,
    },
    {
      id: "3",
      label: `${t("stock_balances.nomenclature")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "5",
      label: `${t("stock_balances.warehouse_room")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "6",
      label: `${t("stock_balances.batch_number")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "7",
      label: `${t("stock_balances.row")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "8",
      label: `${t("stock_balances.section")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "9",
      label: `${t("stock_balances.tier")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "10",
      label: `${t("stock_balances.shelf_life")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "11",
      label: `${t("stock_balances.gross_weight")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "12",
      label: `${t("stock_balances.manufacturer")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "3",
      label: `${t("stock_balances.quantity_goods")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "14",
      label: `${t("stock_balances.cargo_type")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
    {
      id: "14",
      label: `${t("stock_balances.files")}`,
      minWidth: 170,
      format: (value) => value.toFixed(2),
    },
  ];
  const StockMovementHeader = [
    
      {
        id: "2",
        minWidth: 70,
        label: `${t("stock_balances.document")}`,
      },
      {
        id: "3",
        label: `${t("stock_balances.nomenclature")}`,
        minWidth: 120,
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "4",
        label: `${t("stock_balances.vendor_code")}`,
        minWidth: 120,
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "5",
        label: `${t("stock_balances.warehouse_room")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: "6",
        label: `${t("stock_balances.batch_number")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: "7",
        label: `${t("stock_balances.row")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: "8",
        label: `${t("stock_balances.section")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: "9",
        label: `${t("stock_balances.tier")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: "10",
        label: `${t("stock_balances.shelf_life")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: "11",
        label: `${t("stock_balances.gross_weight")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: "12",
        label: `${t("stock_balances.manufacturer")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },  
      {
        id: "14",
        label: `${t("stock_balances.cargo_type")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      },
      {
        id: "15",
        label: `${t("stock_balances.files")}`,
        minWidth: 170,
        format: (value) => value.toFixed(2),
      }
  ];
  const DeclarationsHeader = [
    {
      id: "1",
      label: `${t("declarations.declaration_number")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "2",
      minWidth: 120,
      label: `${t("declarations.date")}`,
    },
    {
      id: "3",
      label: `${t("declarations.delivery")}`,
      minWidth: 80,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "4",
      label: `${t("declarations.expenses")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "5",
      label: `${t("declarations.number")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "6",
      label: `${t("declarations.type_deal")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "7",
      label: `${t("declarations.files")}`,
      minWidth: 120,
      format: (value) => value.toLocaleString("en-US"),
    },
  ];

  return (
    <Context.Provider
      value={{
        token,
        expired_at,
        begin,
        end,
        setBeginDate,
        beginDate,
        endDate,
        setEndDate,
        CargoStatusHeader,
        InvoicesHeader,
        VehiclesZtkHeader,
        VehiclesHeader,
        StockBalancesHeader,
        StockMovementHeader,
        DeclarationsHeader
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
