import axios from "axios";
import { BASE_URL } from "./baseUrl";

export const createReport = async (
  report_name, to, expired_at, archivalChecked) => {
try {
    const data = await axios.get(`${BASE_URL}/report`, {
    params: {
      report_name: report_name,
      token: to,
      expired_at: expired_at,
      archival: archivalChecked,
    },
  });
  return data.data;
} catch (err) {
  return err
}
};
 
  export const findReport = async (
    report_name,
    begin,
    end,
    token,
    expired_at,
    archivalChecked
  ) => {
    const data = await axios.get(`${BASE_URL}/report`, {
      params: {
        report_name: report_name,
        begin_date: begin,
        end_date: end,
        token: token,
        expired_at: expired_at,
        archival: archivalChecked,
      },
    });
    return data.data;
  };