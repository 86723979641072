import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from "react-router"
import { Switch, Route } from 'react-router-dom'
import { Alert, Snackbar } from '@mui/material'
import { useTranslation } from 'react-i18next';

import {
    LoginPage, RegisterPage, RestorePassword, CargoStatusTable,
    InvoicesTable, VehiclesZtk, Vehicles, StockBalances, StockMovement,
    CustomsDeclarations
} from './pages/index'
import { Header } from './components/index'

const privateRoutes = [
    { path: "/cargo_status", exact: true, component: CargoStatusTable },
    { path: "/invoices", exact: true, component: InvoicesTable },
    { path: "/vehicles_ztk", exact: true, component: VehiclesZtk },
    { path: "/vehicles", exact: true, component: Vehicles },
    { path: "/stock_balances", exact: true, component: StockBalances },
    { path: "/stock_movement", exact: true, component: StockMovement },
    { path: "/declaring", exact: true, component: CustomsDeclarations }
]

const publicRoutes = [
    { path: "/login", exact: true, component: LoginPage },
    { path: "/register", exact: true, component: RegisterPage },
    { path: "/restore", exact: true, component: RestorePassword }
]

const App = () => {
    const { isLogged } = useSelector(state => state.auth)
    const [successLoading, setSuccessLoading] = useState(!!isLogged)
    const { t } = useTranslation()

    const closeSnackBar = () => {
        setTimeout(() => {
            setSuccessLoading(false)
        }, 1000);
    }

    return (
        <div className='app'>
            <Header />
            {isLogged &&
                <Snackbar
                    autoHideDuration={1000}
                    onClose={closeSnackBar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={successLoading}>
                    <Alert
                        open={successLoading}
                        severity="success"
                    >
                        {t("alert.successfully_atorized")}
                    </Alert>
                </Snackbar>
            }
            {!isLogged ?
                <Switch>
                    {publicRoutes.map(route => (
                        <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            component={route.component} />
                    ))}
                    <Redirect to="/login" />
                </Switch>
                :
                <Switch>
                    {privateRoutes.map(route => (
                        <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            component={route.component} />
                    ))}
                    <Redirect to="/cargo_status" />
                </Switch>
            }
        </div>
    )
}

export default App
