import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import AppContext from './AppContext';
import { Loading } from './i18n'

import './index.scss';

ReactDOM.render(
  <Suspense fallback={Loading}>
    <Provider store={store}>
      <AppContext>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppContext>
    </Provider>
  </Suspense >,
  document.getElementById('root')
);

