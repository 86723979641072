import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import {
    Button,
    FormControl,
    FormGroup,
    TextField,
    Typography,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Link } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from 'yup';
import { useSelector } from 'react-redux'
import "./LoginPage.scss";
import { AlertComponent, Footer } from '../../components/index'
import { loginAC } from "../../redux/actions/authActions";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const LoginFormSchema = yup.object().shape({
    login: yup.string(),
    password: yup.string().required("Required field").min(5, 'Minimum length 6 characters')
})

const LoginPage = () => {
    const [visibleAlert, setVisibleAlert] = useState(false)
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(LoginFormSchema)
    });
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { errorMessage, isLoading } = useSelector(({ auth }) => auth)
    const { sessionFailed } = useSelector(({ reports }) => reports)

    const onSubmit = (data) => {
        dispatch(loginAC(data.login, data.password))
    }

    useEffect(() => {
        setTimeout(() => {
            setVisibleAlert(true)
        }, 2000);
    }, [])

    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const session = t("alert.error_session")

    return (
        <div className="login">
            {visibleAlert && (
                <Alert
                    className="login-alert"
                    onClose={() => setVisibleAlert(false)}
                    severity="info">
                    <AlertTitle> {t("alert.info_title")} </AlertTitle>
                    {t("alert.info_text")}
                </Alert>
            )}

              {sessionFailed ?
                <AlertComponent t={t} isOpen={true} error={session} /> : null}
                
            <div className="login__form">
                <Typography variant="h5" className="login__form-title">
                    {t("login.login_title")}
                    {}
                </Typography>
                <FormControl component="fieldset" fullWidth>
                    <FormGroup>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="login"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="login__form-input"
                                        label={`${t("form.email")}`}
                                        variant="outlined"
                                        fullWidth
                                        autoFocus
                                        margin="normal"
                                        helperText={errors.login?.message}
                                        error={!!errors.login || !!errorMessage}
                                    />
                                )}
                            />
                           { <Controller
                                sx={{ m: 6 }}
                                control={control}
                                defaultValue=""
                                name="password"
                                className="login__form-password"
                                render={({ field }) => {
                                    return (
                                        <>
                                            <OutlinedInput
                                                {...field}
                                                type={showPassword ? 'text' : 'password' }
                                                placeholder={`${t("form.password")}`}
                                                value={field.value}
                                                variant="outlined"
                                                fullWidth
                                                error={!!errors.password || !!errorMessage}
                                                endAdornment={
                                                     <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                     </InputAdornment>
                                                }
                                            />
                                            <FormHelperText error id="accountId-error">
                                                {errors.password?.message}
                                            </FormHelperText>
                                           
                                        </>
                                    )}}
                            />} 

                            <Button
                                type="submit"
                                variant="outlined"
                                className="login__form-button"
                                fullWidth>
                                {isLoading ? `${t("button.wait")}`
                                    :
                                    `${t("button.login")}`
                                }
                            </Button>
                        </form>
                        <Link
                            className="login__form-redirect"
                            to="/register"> {t("login.no_account")}?</Link>
                        <Link
                            className="login__form-redirect"
                            to="/restore"> {t("login.forgot_password")}</Link>
                    </FormGroup>

                    {errorMessage && (
                        errorMessage.message === "Request failed with status code 401" || errorMessage.message === "Request failed with status code 404"
                            ?
                            (<div className="login__form-error">
                                <ErrorOutlineIcon sx={{ color: 'rgba(169, 2, 2, 0.839)' }} />
                                <span> {t("alert.not_user")} </span>
                            </div>) :
                            (<div className="login__form-error">
                                <ErrorOutlineIcon sx={{ color: 'rgba(169, 2, 2, 0.839)' }} />
                                <span> {t("alert.1с_error")}: {errorMessage.message } </span>
                            </div>)

                    )}
                </FormControl>
            </div>
            <Footer />
        </div>
    );
};

export default LoginPage;
