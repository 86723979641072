import React, { useContext, useState, useEffect, useCallback } from 'react'
import {
  FormControl,
  FormGroup,
  Table, TableBody,
  TableCell, TableContainer, TableHead,
  TableRow,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useDispatch, useSelector } from 'react-redux'
import { downloadReportAction, findReportAction } from '../../redux/actions/reportsActions'
import { Context } from '../../AppContext';
import { useHistory } from 'react-router';
import { SearchInput, Loader } from '../../components/index';
import DatePicker from '@mui/lab/DatePicker';
import { useTranslation } from 'react-i18next';
import ruLocale from "date-fns/locale/ru";
import { downloadExel } from '../../helpers/exportToExel' 

import './style.scss'
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../API/baseUrl';
import AlertComponent from '../../components/AlertComponent/index';

const CargoStatusTable = () => {
  const { t } = useTranslation()
  const [searchValue, setsearchValue] = useState('');
  const history = useHistory()
  const dispatch = useDispatch()
  const { report, error } = useSelector(({ reports }) => reports)
  const { isLoading } = useSelector(state => state.reports)
  const report_name = history.location.pathname.slice(1)
  const [archivalChecked, setArchivalChecked] = useState(false);
  const { token, expired_at, begin,
    end, beginDate, setBeginDate,
    endDate, setEndDate, CargoStatusHeader } = useContext(Context)
  let resulTable = report?.Shippings?.Shipping 

  useEffect(() => {
    dispatch(downloadReportAction(
      report_name,
      token,
      expired_at,
      archivalChecked
    ))
  }, [report_name,
    token,
    expired_at,
    archivalChecked, dispatch, begin,end])

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(findReportAction(
      report_name, begin,
      end, token, expired_at,
      archivalChecked
    ))
  }

  const filterData = resulTable && Object.values(resulTable).filter(el => {
    return el?.Number?.toLowerCase().includes(searchValue.toLowerCase()) ||
      el?.Vehicle?.toLowerCase().includes(searchValue.toLowerCase())
  })

  function sortData({ tableData, sortKey, reverse }) {
    if (!sortKey) return tableData;
    
    const sortedData = filterData?.sort((a, b) => {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    });
    if (reverse) {
      return sortedData.reverse();
    }
    return sortedData;
  }

  function SortButton({ sortOrder, columnKey, sortKey, onClick}) {
    return (
      <button
        onClick={onClick}
        className={`${sortKey === columnKey && sortOrder === "desc"
            ? "sort-button sort-reverse"
            : "sort-button"
          }`}
      >▲</button>
    );
  }

  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("ascn");

  const sortedData = useCallback(() => sortData({ tableData: filterData, sortKey, reverse: sortOrder === "desc" }),
    [sortData, filterData, sortKey, sortOrder]
  )

  function changeSort(key) {
    setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");
    setSortKey(key);
  }

  return (
    <>
      <div className='report'>
        <div className="report__form-header">
         <SearchInput
            toolText={t("tooltip.cargo_status")}
            value={searchValue}
            setValue={setsearchValue} />

          <FormControl fullWidth >
            <FormGroup>
              <form onSubmit={onSubmit}>
                <div className="report__form-date">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ruLocale}>
                    <DatePicker
                      mask="mm.dd.yy"
                      label={`${t("calendar.begin_date")}`}
                      value={beginDate}
                      maxDate={endDate}
                      onChange={(newValue) => setBeginDate(newValue)}
                      renderInput={(params) => <TextField
                        className="datepicker"
                        {...params}
                      />}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ruLocale}>
                    <DatePicker
                      label={`${t("calendar.end_date")}`}
                      value={endDate}
                      mask="mm.dd.yy"
                      maxDate={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      renderInput={(params) => <TextField
                        className="datepicker"
                        {...params}
                      />}
                    />
                  </LocalizationProvider>

                  <div className="report__form-check">
                    <FormControlLabel
                      control={<Checkbox
                        checked={archivalChecked} onChange={(e) => setArchivalChecked(e.target.checked)}
                      />}
                      label={t("form.archival_check")}
                    />
                  </div>
                  <Button type="submit">
                    <p className="btn"> {t("button.show")}</p>
                  </Button>
                </div>
              </form>
            </FormGroup>
          </FormControl>
          <button
            className="btn-sm"
            onClick={() => downloadExel(report_name, "#table")}> <p> {t("button.download_excel")} </p>
          </button>
        </div>

        {error ?
          <AlertComponent t={t} isOpen={true} error={error} /> : null}

        {isLoading ? <Loader /> :
          <div className="report__form-data">
            <TableContainer className="table__height">
              <Table stickyHeader id='table'> 
                <TableHead>
                  <TableRow hover>
                    {CargoStatusHeader.map((row, index) => (
                      <TableCell
                        key={index}
                        className="table-header"
                        style={{
                          minWidth: row.minWidth,
                          textAlign: "center",
                          background: "#f4f4f4",
                          borderRight: "1px solid #ccc",
                        }}>
                        {(row.key === "Date" || row.key === "ToCrossDockUnLoadingFact" || row.key === "SupplierLoadingPlan" || 
                          row.key === "SupplierUnLoadingPlan" ||
                          row.key === "SupplierLoadingFact" || row.key === "SupplierUnLoadingFact" ||
                          row.key === "ToCrossDockLoadingPlan" || row.key === "ToCrossDockLoadingFact" ||
                          row.key === "ToCrossDockUnLoadingPlan") ? (
                            <p onClick={() => changeSort(row.key)}> 
                              {row.label}{" "}
                              <SortButton
                                columnKey={row.key}
                                {...{
                                  sortOrder,
                                  sortKey,
                                }}
                              /> 
                            </p>
                          ): <p> {row.label}</p> }

                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {resulTable ? (
                  Array.isArray(resulTable) ? (
                    <TableBody>
                      {sortedData().map((el, index) => (
                        <TableRow hover key={index}>
                          <TableCell className="table-cell">
                            {el.Number}
                          </TableCell>
                          <TableCell className="table-cell">
                              {el.Date}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.Vehicle}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.Driver}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.Counterparty.Sender}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.Counterparty.Address}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.Invoices.Invoice.Number}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.NumberOfDeclaration}
                          </TableCell>
                          <TableCell
                            className="table-cell">
                            {el.CargoDescriptions?.CargoDescription.length > 1 ? (
                              el.CargoDescriptions?.CargoDescription.map((d, index) => (
                                <p key={index}> {d.Description} </p>
                              ))
                            ) : el.CargoDescriptions?.CargoDescription?.Description
                            }
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.ContactPerson}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.CargoStatus}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.CargoCost}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.LoadingMeter}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.Tonnage}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.DeliveryCost}
                          </TableCell>

                          <TableCell className="table-cell"> 
                            {el.SupplierLoadingPlan ? el.SupplierLoadingPlan : "-"}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.SupplierLoadingFact ? el.SupplierLoadingFact : "-"}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.SupplierUnLoadingPlan ? el.SupplierUnLoadingPlan : "-" }
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.SupplierUnLoadingFact ? el.SupplierUnLoadingFact : "-"}
                          </TableCell>

                          <TableCell className="table-cell">
                            {el.ToCrossDockLoadingPlan ? el.ToCrossDockLoadingPlan : "-" }
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.ToCrossDockLoadingFact ? el.ToCrossDockLoadingFact : "-"}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.ToCrossDockUnLoadingPlan ? el.ToCrossDockUnLoadingPlan : "-" }
                          </TableCell> 
                          <TableCell className="table-cell">
                            {el.ToCrossDockUnLoadingFact ? el.ToCrossDockUnLoadingFact : "-"}
                          </TableCell>

                          <TableCell
                            className="table-cell">
                            {el.ArrivalDate}
                          </TableCell>
                          <TableCell className="table-cell">
                            {el.Files?.File?.length > 1 ? (
                              el.Files?.File.map((file, index) => (
                                <b key={index}>
                                  <Link
                                    className="dock"
                                    to={{
                                      pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.Number}&filename=${file.name}`
                                    }} target="_blank" download={file.name}>
                                    {file.name}
                                    <br />
                                  </Link> </b>
                              )))
                              :
                              (<b key={index}> <p>
                                <Link
                                  className="dock"
                                  to={{
                                    pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.Number}&filename=${el?.Files?.File?.name}`
                                  }} target="_blank" download={el?.Files?.File.name}>
                                  {el?.Files?.File.name}
                                </Link>
                              </p> </b>
                              )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) :
                    <TableBody>
                      <TableRow>
                        <TableCell className="table-cell">
                          {resulTable.Number}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.Date}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.Vehicle}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.Driver}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.Counterparty.Sender}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.Counterparty.Address}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.Invoices.Invoice.Number}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.NumberOfDeclaration}
                        </TableCell>
                        <TableCell
                          className="table-cell">
                          {resulTable.CargoDescriptions?.CargoDescription.length > 1 ? (
                            resulTable.CargoDescriptions?.CargoDescription.map((d, index) => (
                              <p key={index}> {d.Description} </p>
                            ))
                          ) : resulTable.CargoDescriptions?.CargoDescription?.Description
                          }
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.ContactPerson}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.CargoStatus}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.CargoCost}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.LoadingMeter}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.Tonnage}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.DeliveryCost}
                        </TableCell>

                        <TableCell className="table-cell">
                          {resulTable.SupplierLoadingPlan}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.SupplierLoadingFact}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.SupplierUnLoadingPlan}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.SupplierUnLoadingFact}
                        </TableCell>

                        <TableCell className="table-cell">
                          {resulTable.ToCrossDockLoadingPlan}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.ToCrossDockLoadingFact}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.ToCrossDockUnLoadingPlan}
                        </TableCell>
                        <TableCell className="table-cell">
                          {resulTable.ToCrossDockUnLoadingFact}
                        </TableCell>

                        <TableCell
                          className="table-cell">
                          {resulTable.ArrivalDate}
                        </TableCell>
                      
                        <TableCell className="table-cell">
                          {resulTable?.Files?.File?.length > 1 ? (
                            resulTable?.Files?.File.map((file, index) => (
                              <b key={index}>
                                <Link
                                  className="dock"
                                  to={{
                                    pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.Number}&filename=${file.name}`
                                  }} target="_blank" download={file.name}>
                                  {file.name}
                                  <br />
                                </Link> </b>
                            )))
                            :
                            (<b key={resulTable?.Files?.File.name}> <p>
                              <Link
                                className="dock"
                                to={{
                                  pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.Number}&filename=${resulTable?.Files?.File.name}`
                                }} target="_blank" download={resulTable?.Files?.File.name}>
                                {resulTable?.Files?.File.name}
                              </Link>
                            </p> </b>
                            )}
                        </TableCell>

                      </TableRow>
                    </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={16} className="row">
                        <p>{t("data.no_data")}
                        </p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        }
      </div>
    </>
  )
}

export default CargoStatusTable


