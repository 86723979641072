import React, { useState } from "react";
import {
    Button,
    FormControl,
    FormGroup,
    TextField,
    Typography,
} from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { registerAC } from "../../redux/actions/authActions";
import './RegisterPage.scss'
import Dialog from '@mui/material/Dialog';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Footer } from "../../components";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const RegisterFormSchema = yup.object().shape({
    email: yup.string().required('Required field'),
    firstName: yup.string().required('Required field'),
    lastName: yup.string().required("Required field"),
    company: yup.string().required("Required field"),
    phone: yup.string().required("Required field").min(8),
    password: yup.string().min(4).max(20).required(),
    password2: yup.string().oneOf([yup.ref('password')], 'Passwords do not match'),
});

const RegisterPage = () => {
    const dispatch = useDispatch()
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(RegisterFormSchema)
    });

    const [openModal, setOpenModal] = useState(false)
    const { error } = useSelector(({ auth }) => auth)

    const history = useHistory()
    const { t } = useTranslation()
    const onSubmit = (data) => {
        dispatch(registerAC(
            data.firstName, 
            data.lastName, data.email, 
            data.phone, data.password, 
            data.company
            ))
        setOpenModal(true)
    }

    const handleClose = () => {
        setOpenModal(false)
        history.push('/login')
    }

    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className="register">
            <div className="register__form">
                <Typography variant="h5" className="register__form-title">
                    {t("register.register_title")}
                </Typography>
                 <FormControl component="fieldset" fullWidth>
                    <FormGroup aria-label="position" row>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={t("register.email")}
                                        variant="outlined"
                                        fullWidth
                                        helperText={errors.email?.message}
                                        error={!!errors.email}
                                        className="register__form-input"
                                    />
                                )}
                            />
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={t("register.first_name")}
                                        variant="outlined"
                                        fullWidth
                                        helperText={errors.firstName?.message}
                                        error={!!errors.firstName}
                                        className="register__form-input"
                                    />
                                )}
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={t("register.last_name")}
                                        variant="outlined"
                                        fullWidth
                                        helperText={errors.lastName?.message}
                                        error={!!errors.lastName}
                                        className="register__form-input"
                                    />
                                )}
                            />

                            <Controller
                                name="company"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={t("register.company")}
                                        variant="outlined"
                                        fullWidth
                                        className="register__form-input"
                                        helperText={errors.company?.message}
                                        error={!!errors.company}
                                    />
                                )}
                            />

                            <Controller
                                name="phone"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={t("register.phone_number")}
                                        variant="outlined"
                                        fullWidth
                                        helperText={errors.phone?.message}
                                        error={!!errors.phone}
                                        className="register__form-input"
                                    />
                                )}
                            />

                            <Controller
                               /*  sx={{ m: 6 }} */
                                control={control}
                                defaultValue=""
                                name="password"
                                render={({ field }) => {
                                    return (
                                        <>
                                            <OutlinedInput
                                                {...field}
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder={`${t("form.password")}`}
                                                value={field.value}
                                                variant="outlined"
                                                fullWidth
                                                error={!!errors.password }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <FormHelperText error id="accountId-error">
                                                {errors.password?.message}
                                            </FormHelperText>

                                        </>
                                    )
                                }}
                            />

                            <Controller
                                control={control}
                                defaultValue=""
                                name="password2"
                                render={({ field }) => {
                                    return (
                                        <>
                                            <OutlinedInput
                                                {...field}
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder={`${t("form.password")}`}
                                                value={field.value}
                                                variant="outlined"
                                                fullWidth
                                                error={!!errors.password2}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <FormHelperText error id="accountId-error">
                                                {errors.password2?.message}
                                            </FormHelperText>

                                        </>
                                    )
                                }}
                            />

                            <Button
                                type="submit"
                                variant="outlined"
                                className="register__form-button"
                                fullWidth
                            >
                                {t("button.register")}
                            </Button>
                        </form>
                        <Link
                            className="register__form-redirect"
                            to="/login"> {t("register.have_account")} </Link>
                    </FormGroup>
                        <Dialog
                            open={openModal}
                            onClose={handleClose}>
                            <DialogContent>
                                <DialogContentText>
                                {t("alert.admin_response")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                               
                                <Button onClick={handleClose} autoFocus>
                                ОК
                                </Button>
                            </DialogActions>
                        </Dialog>

                    {error && 
                        <div className="register__form-error">
                            <ErrorOutlineIcon sx={{ color: 'rgba(169, 2, 2, 0.839)' }} />
                            <span> {t("alert.error")} </span>
                        </div>
                    }
                </FormControl> 
            </div>
            <Footer />
        </div>
    )
}

export default RegisterPage
