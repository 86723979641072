import React from 'react'

import './Footer.scss'

const Footer = () => {
    return (
        <div className="footer">
            © ЛК Вавс 2021
        </div>
    )
}

export default Footer
