import { combineReducers } from "redux";
import authReducer from "./authReducer";
import reportshReducer from "./reportsReducer";
import trackReducer from "./trackReducer";
import recoveryReducer from './recoveryReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  reports: reportshReducer,
  track: trackReducer,
  recovery: recoveryReducer,
});