import { BASE_URL } from "./baseUrl";

  const AuthAPI = {
    async register(firstName, lastName, email, phone, password, company) {
      return await fetch(
        `${BASE_URL}/registration/?login=${email}&password=${password}&lastname=${lastName}&name=${firstName}&phonenumber=${phone}&company=${company}`,
        {
          method: "POST",
        }
      ).then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      });
    },

    async login(login, password) {
      return await fetch(
        `${BASE_URL}/session/?login=${login}&password=${password}`,
        {
          method: "POST",
        }
      )
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
        })
        .then((data) => {
          if (data) {
            localStorage.setItem("tokenData", JSON.stringify(data));
            return data;
          }
          return Promise.reject();
        });
    },

    logout() {
      return fetch(`${BASE_URL}/logout`, { method: "GET" }).then((res) => {
        console.log(res);
        localStorage.removeItem("tokenData");
        localStorage.removeItem("user");
      });
    },
  };
  export default AuthAPI;
