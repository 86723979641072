import React, { useState, useEffect } from "react";
import {
    Button,
    FormControl,
    FormGroup,
    TextField,
    Typography,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import './RestorePassword.scss'
import { recoveryPasswordAC } from '../../redux/actions/recoveryActions';
import Alert from '@mui/material/Alert';
import Footer from '../../components/Footer/index';

const LoginFormSchema = yup.object().shape({
    email: yup.string().required("Required field").email('Wrong E-mail. Try again please'),
})

const RestorePassword = () => {
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(LoginFormSchema)
    });
    const dispatch = useDispatch()
    const { sendMailSuccess, errorRecoveryMessage } = useSelector(({ recovery }) => recovery)
    const history = useHistory()
    const [openModal, setOpenModal] = useState(sendMailSuccess)
    const { t } = useTranslation()
    const onSubmit = (data) => {
        dispatch(recoveryPasswordAC(data.email))
    }

    useEffect(() => {
        setOpenModal(sendMailSuccess)
    }, [sendMailSuccess])

    const handleClose = () => {
        setOpenModal(false)
        history.push('/login')
    }

    return (
        <div className="restore">
            <div className="restore__form">
                <Typography variant="h5" className="register__form-title">
                    {t("restore.restore_title")}
                </Typography>
                <FormControl component="fieldset" fullWidth>
                    <FormGroup>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="email"
                                className="restore__form-input"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        autoFocus
                                        margin="normal"
                                        helperText={errors.email?.message}
                                        error={!!errors.email}
                                    />
                                )}
                            />
                            <Button
                                type="submit"
                                variant="outlined"
                                className="register__form-button"
                                fullWidth
                            >
                                {t("button.send")}
                            </Button>
                        </form>
                        <Link
                            className="restore__form-redirect"
                            to="/login">{t("login.login_title")}</Link>
                    </FormGroup>
                </FormControl>

                {errorRecoveryMessage && 
                <Alert  style={{ marginTop: 20 }} severity="error"> 
                Пользователь с таким Email не найден 
                </Alert>
                }

                <Dialog onClose={handleClose} open={openModal}>
                    <DialogTitle id="alert-dialog-title">
                        Проверьте почту
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            На Вашу почту пришел новый пароль
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            закрыть
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Footer />
        </div>
    )
}

export default RestorePassword
