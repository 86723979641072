import { Button, Container } from '@mui/material'
import i18next from 'i18next'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { logoutAC } from '../../redux/actions/authActions'
import { FindTrack } from '../index'
import { useLocation } from "react-router-dom";

import './Header.scss'
import logo from "./logo.svg";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Header = () => {
    const query = useQuery();
    const defLang = query.get("current-locale")
    const currentLang = defLang?.slice(0,2) || localStorage.getItem('i18nextLng')

    const [lang, setLang] = useState(currentLang)

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { isLogged } = useSelector(state => state.auth)

    const logoutClick = () => {
        dispatch(logoutAC())
    }

    const onChangeLang = () => {
        if (lang === 'ru') {
            setLang('en')
            i18next.changeLanguage('en')
        } else {
            setLang('ru')
            i18next.changeLanguage('ru')
        }
    }

    const pagesReport = [
        { id: 1, title: `${t("menu.cargo_status")}`, path: '/cargo_status' },
        { id: 2, title: `${t("menu.invoices")}`, path: '/invoices' },
        { id: 3, title: `${t("menu.vehicles_ztk")}`, path: '/vehicles_ztk' },
        { id: 4, title: `${t("menu.vehicles")}`, path: "/vehicles" },
        { id: 5, title: `${t("menu.stock_balances")}`, path: '/stock_balances' },
        { id: 6, title: `${t("menu.stock_movement")}`, path: '/stock_movement' },
        { id: 7, title: `${t("menu.customs_declarations")}`, path: '/declaring' },
    ]

    useEffect(()=>{
        localStorage.setItem('i18nextLng', currentLang)
        i18next.changeLanguage(currentLang)
    }, [])

    return (
        <>
            {isLogged ? (
                <div className="header">
                   <div className="header__top">
                        <span className="content__header-logo">
                            <a href={"https://vavsgroup.com/" + (lang === 'ru' ? 'ru/' : '')}>
                              <img src={logo}/>
                            </a>
                        </span>
                        <div className="header__rigth">
                            <FindTrack />
                            <div className="header__lang"
                                onClick={() => onChangeLang()}>
                                <div className="btn">
                                    <strong
                                        className={lang === "en" ? "header__lang active" : "header__lang"}
                                    > EN
                                    </strong>
                                    <span> / </span>
                                    <strong
                                        className={lang === "ru" ? "header__lang active" : "header__lang"}
                                    > RU
                                    </strong>
                                </div>
                            </div>
                            <Button
                                onClick={logoutClick}>
                                <p className="btn"> {t('button.logout')} </p>
                            </Button>
                        </div>
                    </div>
                    <div className="header__bottom">
                        {pagesReport.map(link => (
                            <NavLink
                                className="home__menu-link"
                                activeClassName="active"
                                to={link.path}
                                key={link.id}
                            >
                                {link.title}
                            </NavLink>
                        ))}
                    </div>
                </div>
            ) :
            <div className="main_header">
                    <div className="header-login">
                        <span className="content__header-logo">
                            <a href={"https://vavsgroup.com/" + (lang === 'ru' ? 'ru/' : '')}>
                              <img src={logo}/>
                            </a>
                        </span>
                        <div className='header__rigth'>
                            <FindTrack />
                            <div className="header__lang"
                                onClick={() => onChangeLang()}>
                                <div className="btn">
                                    <strong
                                        className={lang === "en" ? "header__lang active" : "header__lang"}> EN </strong>
                                    <span> / </span>
                                    <strong
                                        className={lang === "ru" ? "header__lang active" : "header__lang"}> RU </strong>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

            }
        </>
    )
}

export default Header
