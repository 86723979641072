import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow,
} from '@mui/material';
import { useHistory } from 'react-router';

import { Context } from '../../AppContext';
import { downloadReportAction } from '../../redux/actions/reportsActions';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../components';
import { downloadExel } from '../../helpers/exportToExel'
import SearchInput from '../../components/SearchInput/index';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../API/baseUrl';
import AlertComponent from '../../components/AlertComponent/index';

const StockBalances = () => {
    const [searchValue, setsearchValue] = useState('');
    const dispatch = useDispatch()
    const history = useHistory()
    const report_name = history.location.pathname.slice(1)
    const { token, expired_at, StockBalancesHeader } = useContext(Context)
    const { report, error } = useSelector(({ reports }) => reports)
    const { isLoading } = useSelector(state => state.reports)
    const resulTable = report?.Nomenclatures?.Nomenclature
    const { t } = useTranslation()

    const filterData = resulTable && Object.values(resulTable).filter(el => {
        return el?.PartNumber?.toLowerCase().includes(searchValue.toLowerCase()) 
      })

    useEffect(() => {
        dispatch(downloadReportAction(
            report_name,
            token,
            expired_at))
    }, [report_name,
        token,
        expired_at, dispatch])


    return (
        <>
            <div className="report">
                <div className='report__form-header'>
                    <SearchInput
                        toolText={t("tooltip.stock_balances")}
                        value={searchValue}
                        setValue={setsearchValue} />
                    <button
                        className="btn-sm"
                        onClick={() => downloadExel(report_name, "#table")}> <p> {t("button.download_excel")} </p>
                    </button>
                </div>
                
                {error ?
                    <AlertComponent t={t} isOpen={true} error={error} /> : null}

                {isLoading ? <Loader /> :
                    <div className="report__form-data">
                        <TableContainer className="table__height">
                            <Table stickyHeader id='table'>
                                <TableHead>
                                    <TableRow hover>
                                        {StockBalancesHeader.map((column, index) => (
                                            <TableCell
                                                key={index}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    textAlign: "center",
                                                    background: "#f4f4f4",
                                                    borderRight: "1px solid #ccc",
                                                }}
                                              className="table-header"
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {resulTable ? (
                                    Array.isArray(resulTable) ? (
                                        <TableBody>
                                            {filterData?.map((el, index) => (
                                                <TableRow key={index} hover>
                                                    <TableCell className="table-cell">
                                                        {el.PartNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.DocumentNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Nomenclature}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.WarehouseNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.BatchNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Row}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Section}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Tier}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.ShelfLife}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.GrossWeight}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Manufacturer}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Quantity}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.CargoType}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {el.Files?.File?.length > 1 ? (
                                                            el.Files?.File.map((file, index) => (
                                                                <b key={index}>
                                                                    <Link
                                                                        className="dock"
                                                                        to={{
                                                                            pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.DocumentNumber}&filename=${file.name}`
                                                                        }} target="_blank" download={file.name}>
                                                                        {file.name}
                                                                        <br />
                                                                    </Link> </b>
                                                            )))
                                                            :
                                                            (<b key={index}> <p>
                                                                <Link
                                                                    className="dock"
                                                                    to={{
                                                                        pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.DocumentNumber}&filename=${el?.Files?.File?.name}`
                                                                    }} target="_blank" download={el?.Files?.File.name}>
                                                                    {el?.Files?.File.name}
                                                                </Link>
                                                            </p> </b>
                                                            )}
                                                    </TableCell>
                                                </TableRow>
                                            ))} 
                                        </TableBody>
                                    ) :
                                        (
                                            <TableBody>
                                                <TableRow hover>
                                                    <TableCell className="table-cell">
                                                        {resulTable.PartNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.DocumentNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.Nomenclature}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.WarehouseNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.BatchNumber}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.Row}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.Section}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.Tier}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.ShelfLife}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.GrossWeight}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.Manufacturer}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.Quantity}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable.CargoType}
                                                    </TableCell>
                                                    <TableCell className="table-cell">
                                                        {resulTable?.Files?.File?.length > 1 ? (
                                                            resulTable?.Files?.File.map((file, index) => (
                                                                <b key={index}>
                                                                    <Link
                                                                        className="dock"
                                                                        to={{
                                                                            pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.DocumentNumber}&filename=${file.name}`
                                                                        }} target="_blank" download={file.name}>
                                                                        {file.name}
                                                                        <br />
                                                                    </Link> </b>
                                                            )))
                                                            :
                                                            (<b key={resulTable?.Files?.File.name}> <p>
                                                                <Link
                                                                    className="dock"
                                                                    to={{
                                                                        pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.DocumentNumber}&filename=${resulTable?.Files?.File.name}`
                                                                    }} target="_blank" download={resulTable?.Files?.File.name}>
                                                                    {resulTable?.Files?.File.name}
                                                                </Link>
                                                            </p> </b>
                                                            )}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )
                                ) : <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={16} className="row">
                                            <p>{t("data.no_data")}</p>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                }
                            </Table>
                        </TableContainer>
                    </div>
                }
            </div>
        </>
    )
}

export default StockBalances
