import { findTrackNumber } from "../../API/trackApi";

export const LOADING_TRACK = "LOADING_TRACK";
export const SUCCESS_FIND_TRACK = "SUCCESS_FIND_TRACK";
export const ERROR_FIND_TRACK = "ERROR_FIND_TRACK";

export const loadingTrackAction = (payload) => ({
  type: LOADING_TRACK,
  payload,
});

export const successFindTrack = (payload) => ({
  type: SUCCESS_FIND_TRACK,
  payload,
});

export const errorFindTrack = (payload) => ({
  type: ERROR_FIND_TRACK,
  payload,
});

export const findTrackAction = (trackNuber) => async (dispatch) => {
  try {
    dispatch(loadingTrackAction(true));
    const data = await findTrackNumber(trackNuber);
    dispatch(successFindTrack(data));
    dispatch(loadingTrackAction(false));
  } catch (e) {
    dispatch(errorFindTrack(e));
    dispatch(loadingTrackAction(false));
  } finally {
    dispatch(loadingTrackAction(false));
  }
};
