import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    FormControl,
    FormGroup,
    Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom'
import ruLocale from "date-fns/locale/ru";
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { downloadExel } from '../../helpers/exportToExel'
import { Context } from '../../AppContext';
import { downloadReportAction, findReportAction } from '../../redux/actions/reportsActions';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../components';
import { BASE_URL } from '../../API/baseUrl';
import './style.scss'
import AlertComponent from '../../components/AlertComponent/index';

const CustomsDeclarations = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const report_name = history.location.pathname.slice(1)
    const { token, expired_at, begin,
        end, beginDate, setBeginDate,
        endDate, setEndDate, DeclarationsHeader } = useContext(Context)
    const { report, error } = useSelector(({ reports }) => reports)
    let resulTable = report?.Declarations?.Declaration

    const { isLoading } = useSelector(state => state.reports)
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(downloadReportAction(
            report_name,
            token,
            expired_at))
    }, [report_name,
        token,
        expired_at, dispatch])

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(findReportAction(
            report_name, begin,
            end, token, expired_at))
    }

    return (
        <>
            <div className="report">
                <div className="report__form-header">
                    <FormControl fullWidth>
                        <FormGroup>
                            <form onSubmit={onSubmit}>
                                <div className="report__form-options">
                                    <div className="report__form-date">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={ruLocale}>
                                            <DatePicker
                                                mask="mm.dd.yy"
                                                label={`${t("calendar.begin_date")}`}
                                                value={beginDate}
                                                onChange={(newValue) => setBeginDate(newValue)}
                                                renderInput={(params) => <TextField
                                                    className="datepicker"
                                                    {...params}
                                                />}
                                            />
                                        </LocalizationProvider>

                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={ruLocale}>
                                            <DatePicker
                                                label={`${t("calendar.end_date")}`}
                                                value={endDate}
                                                onChange={(newValue) => setEndDate(newValue)}
                                                renderInput={(params) => <TextField
                                                    className="datepicker"
                                                    {...params}
                                                />}
                                            />
                                        </LocalizationProvider>
                                        <Button type="submit">
                                            <p className="btn">  {t("button.show")} </p>
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </FormGroup>
                    </FormControl>
                    <button
                        className="btn-sm"
                        onClick={() => downloadExel(report_name, "#table")}> <p> {t("button.download_excel")} </p>
                    </button>
                </div>

                {error ?
                    <AlertComponent t={t} isOpen={true} error={error} /> : null}

                {isLoading ? <Loader /> :
                    <TableContainer className="table__height">
                        <Table stickyHeader id='table'>
                            <TableHead>
                                <TableRow hover>
                                    {DeclarationsHeader.map((column, index) => (
                                        <TableCell
                                            key={index}
                                            style={{
                                                minWidth: column.maxWidth,
                                                textAlign: "center",
                                                background: "#f4f4f4",
                                                borderRight: "1px solid #ccc",
                                            }}
                                            className="table-header"
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            { resulTable ? (
                                Array.isArray(resulTable) ? (
                                    <TableBody>
                                        {resulTable?.map((el, index) => (
                                            <TableRow key={index} hover>
                                                <TableCell className="table-cell">
                                                    {el.DeclarationNumber}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el.Date}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el.DeliveryConditions}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el.Expenses}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el.Number}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {el.TypeOfDeal}
                                                </TableCell>
                                              
                                                <TableCell className="table-cell">
                                                        {el?.Files ? (
                                                            el?.Files?.File.length > 1 ? (
                                                                el?.Files?.File.map(file => (
                                                                    <b key={file.name}>
                                                                        <Link
                                                                            className="dock"
                                                                            to={{
                                                                                pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.Number}&filename=${file.name}`
                                                                            }} target="_blank" download={file.name}>
                                                                            {file.name}
                                                                            <br />
                                                                        </Link> </b>
                                                                )))
                                                                :
                                                                (<b key={el?.Files?.File?.name}> <p>
                                                                    <Link
                                                                        className="dock"
                                                                        to={{
                                                                            pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${el.Number}&filename=${el?.Files?.File?.name}`
                                                                        }} target="_blank" download={el?.Files?.File?.name}>
                                                                        {el?.Files?.File?.name}
                                                                    </Link>
                                                                </p> </b>
                                                                )
                                                        ) : null}
                                                    </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow hover>
                                                <TableCell className="table-cell">
                                                    {resulTable.DeclarationNumber}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable.Date}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable.DeliveryConditions}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable.Expenses}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable.Number}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable.TypeOfDeal}
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    {resulTable?.Files?.File?.length > 1 ? (
                                                        resulTable?.Files?.File.map(file => (
                                                            <b key={file.name}>
                                                                <Link
                                                                    className="dock"
                                                                    to={{
                                                                        pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.Number}&filename=${file.name}`
                                                                    }} target="_blank" download={file.name}>
                                                                    {file.name}
                                                                    <br />
                                                                </Link> </b>
                                                        )))
                                                        :
                                                        (<b key={resulTable?.Files?.File.name}> <p>
                                                            <Link
                                                                className="dock"
                                                                to={{
                                                                    pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${report_name}&token=${token}&document_number=${resulTable.Number}&filename=${resulTable?.Files?.File.name}`
                                                                }} target="_blank" download={resulTable?.Files?.File.name}>
                                                                {resulTable?.Files?.File.name}
                                                            </Link>
                                                        </p> </b>
                                                        )}
                                                </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )
                            ) : <TableBody>
                                <TableRow>
                                    <TableCell colSpan={16} className="row">
                                        <p>{t("data.no_data")}</p>
                                    </TableCell>
                                </TableRow>
                            </TableBody>}
                        </Table>
                    </TableContainer>
                }
            </div>
        </>
    )
}

export default CustomsDeclarations
