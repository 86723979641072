import {
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_ERROR,
} from "../actions/recoveryActions";

const initialStaete = {
  sendMailSuccess: false,
  errorRecoveryMessage: false,
};

const recoveryReducer = (state = initialStaete, action) => {
  switch (action.type) {
    case RESTORE_PASSWORD_SUCCESS:
      return {
        ...state,
        sendMailSuccess: true,
        errorRecoveryMessage: action.payload,
      };

    case RESTORE_PASSWORD_ERROR:
      return {
        ...state,
        sendMailSuccess: false,
        errorRecoveryMessage: action.payload,
      };

    default:
      return state;
  }
};
export default recoveryReducer;
