import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

const SearchInput = ({ value, setValue, toolText}) => {
    const { t } = useTranslation()

    return (
        <Tooltip title={toolText} arrow>
            <OutlinedInput
                value={value}
                placeholder={t("form.search")}
                onChange={(e) => setValue(e.target.value)}
                startAdornment={<InputAdornment><SearchIcon /></InputAdornment>}
            />
        </Tooltip>
    )
}

export default SearchInput
