import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Modal, TextField} from '@mui/material'
import {Loader} from '../index'
import {useDispatch, useSelector} from 'react-redux'
import {findTrackAction, successFindTrack} from '../../redux/actions/trackActions'
import {makeStyles} from '@mui/styles';

import './FindTrack.scss'
import {Link} from 'react-router-dom';
import {BASE_URL} from '../../API/baseUrl'
import {Context} from '../../AppContext'

const FindTrack = () => {
    const [open, setOpen] = useState(false);
    const [trackNuber, setTrackNuber] = useState('')
    const handleOpen = () => setOpen(true);
    const { token, expired_at } = useContext(Context)
    const handleClose = () => {
        setOpen(false)
        setTrackNuber("")
        dispatch(successFindTrack(null));
    };
    const { error, isLoading, data } = useSelector(state => state.track)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const findTrackNumber = () => {
        dispatch(findTrackAction(trackNuber))
    }
    const useStyles = makeStyles({
        textField: {
            [`& fieldset`]: {
                borderRadius: 0,
            },
        }
    })

    const styles = useStyles()
    return (
        <div className="track">
            <Button onClick={handleOpen}>
                <p className="btn">{t("button.search_track")} </p>
                 </Button>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}>
                <div className="box">
                    <p className="track__title">
                        {t("track.title")}
                    </p>
                    <div className="track__form">
                        <TextField
                            className={styles.textField}
                            variant="outlined"
                            fullWidth
                            placeholder="0JDQm9CjMjM4NA"
                            sx={{
                                maxWidth: 'unset',
                                visibility: open ? 'visible' : 'hidden',
                                height: 'unset'
                            }}
                            value={trackNuber}
                            onChange={(e) => setTrackNuber(e.target.value)}
                        />
                        <Button
                            disabled={!trackNuber}
                            onClick={findTrackNumber}
                            variant="outlined"> {t("button.search")} </Button>
                    </div>
                        <div className="track__data">
                          
                        {isLoading &&
                            <div className="track__data-loading">
                                <Loader />
                            </div>
                        }
                        {data && data.number ? (
                            <div style={{ paddingLeft: 30 }}>
                                <ul className="track__data-list">
                                    <li> <b> {t("track.number")} </b> : {data.number}</li>
                                    <li> <b> {t("track.date")} </b> : {data.date}</li>
                                    <li> <b> {t("track.sender")} </b> : {data.sender}</li>
                                    <li> <b> {t("track.addressLoading")}</b> : {data.addressLoading}</li>
                                    <li> <b> {t("track.description")} </b> : {data.description}</li>
                                    <li> <b> {t("track.cargoStatus")} </b> : {data.cargoStatus}</li>
                                    <li> <b> {t("track.ldm")} </b> : {data.ldm}</li>
                                    <li> <b> {t("track.tonnage")} </b> : {data.tonnage}</li>
                                    <li> <b> {t("track.shipmentDate")}</b> : {data.shipmentDate}</li>
                                    <li> <b> {t("track.shipmentDateAfterCD")}</b> : {data.shipmentDateAfterCD}</li>
                                    <li> <b> {t("track.dateUnloading")} </b> : {data.dateUnloading} </li>
                                    <li> <b>  {t("track.files")} </b>:{data.files.map((file, index) => (
                                        <li>
                                          <>
                                                {index + 1}.
                                                <Link
                                                    className={token ? "downloadLink" : "downloadLink-disabled"} key={index}
                                                    to={{
                                                        pathname: `${BASE_URL}/report_file?expired_at=${expired_at}&report_name=${'cargo_status'}&token=${token}&document_number=${data.number}&filename=${file}`
                                                    }} target="_blank" download={file}> {file} </Link>
                                          </>
                                        </li> 
                                      
                                    ))}</li>
                                </ul>
                               { !token && (
                                    <div className="download_info">
                                        Чтобы скачать файлы, Вам нужно авторизоваться
                                    </div>
                               ) }
                            </div>
                        ) : (
                            data && <span> {t("track.not_found")} </span>
                        )}
                        {error && (
                            <p> {error.message} </p>
                        )}
                        </div>
                </div>
            </Modal>
        </div>
    )
}

export default FindTrack
