import axios from "axios"
import { BASE_URL } from './baseUrl'

export const findTrackNumber = async (trackNuber) => {
  const data = await axios.get(`${BASE_URL}/tracker`, {
    params: {
      track_number: trackNuber 
    },
  });
  return data.data;
};
