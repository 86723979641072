import axios from "axios";
import { BASE_URL } from "../../API/baseUrl";

export const RESTORE_PASSWORD_SUCCESS = "RESTORE_PASSWORD_SUCCESS";
export const RESTORE_PASSWORD_ERROR = "RESTORE_PASSWORD_ERROR";
export const CLOSE_RECOVERY = "CLOSE_RECOVERY";

export const recoveryPasswordSuccess = () => ({
  type: RESTORE_PASSWORD_SUCCESS,
});
export const recoveryPassworError = (payload) => ({
  type: RESTORE_PASSWORD_ERROR,
  payload,
});

export const recoveryPasswordAC = (email) => async (dispatch) => {
  try {
    const data = await axios.post(
      `${BASE_URL}/recovery_password/?login=${email}`
    );
    if (data.status === 200) {
      dispatch(recoveryPasswordSuccess());
    } else {
      dispatch(recoveryPassworError(true));
    }
  } catch (err) {
    dispatch(recoveryPassworError(true));
  } finally {
  }
};
